.wrapper {
  .section-wrapper {
    border-bottom: 1px solid #ccc;
    section {
      margin: 0 auto;
      padding: 30px 20px;
      .content {
        margin: 0 auto;
        .title {
          text-transform: uppercase;
          color: var(--red);
          font-weight: 800;
          font-family: TTNormsPro-Bold;
          font-size: 30px;
          margin-bottom: 10px;
        }

        .des {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        }
      }
    }

    .quiz {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .content {
        .title {
          text-align: center;
        }
        .button {
          width: 100%;
          margin: 0 auto;
          margin-top: 25px;
        }
      }
      .banner {
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }

    .advise {
      .content {
        text-align: center;
        margin-bottom: 40px;
      }

      .adviser-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
 
   
        row-gap: 60px;
        .adviser {
          position: relative;
          width: 260px;
          height: 380px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          background: linear-gradient(
            0deg,
            var(--color-from) 0%,
            var(--color-to) 100%
          );
          border-radius: 30px;
        
    

          img {
            
       
            scale: 0.7;
            transform-origin: bottom;
            
          }
          p {
            color: var(--red);

            font-size: 20px;
            font-weight: 800;
            text-align: center;
            position: absolute;
            top: 101%;
          }
        }
      }

      .button {
        margin: 35px 0;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .exp {
      .content {
        text-align: center;
        margin-bottom: 30px;
      }
      .exp-form {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 50px;
        margin-bottom: 40px;
        .card {
          border-radius: 30px;
          background-color: #ccc;
          position: relative;
          width: 300px;
          height: 400px;
          margin: 0 auto;

          img {
            position: absolute;
            bottom: 2px;
            left: -0;

            height: 105%;
            width: 100%;
            object-fit: cover;
            filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.8));
          }
        }
        .form {
          background-color: #ad7ae7;
          border-radius: 25px 0px 25px 0px;

          padding: 20px;
          .form-group {
            width: 100%;
            margin-bottom: 15px;
            &:last-of-type {
              padding-bottom: 30px;
              border-bottom: 3px solid #e7a6aa;
            }
            label {
              color: var(--white);
              cursor: pointer;
            }

            input {
              height: 40px;
              width: 100%;
              padding: 0 10px;
              line-height: 40px;
              background-color: #e7a6aa;
              border-radius: 10px 0px 10px 0px;
              color: var(--white);
              font-size: 16px;
            }
          }
          .button {
            background-color: var(--red);
            padding: 10px;
            color: var(--white);
            border-radius: 90px;
            width: 100%;
            font-weight: 800;
            font-family: TTNormsPro-Bold;
            margin-top: 15px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .wrapper {
    .section-wrapper {
      section {
        max-width: 520px;
      }

      .advise {
        .adviser-list {
          padding: 0;
         
         column-gap: 18px;
          .adviser {
            width: 48%;
            padding: 0 20px;
            img {
       
             scale: 1;
            }
            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .wrapper {
    .section-wrapper {
      section {
        max-width: 740px;
      }
      .advise {
        .adviser-list {
          .adviser {
           

            img {
              scale: 0.7;
            }
          }
        }
       
      }
    }
  }
}

@media (min-width: 992px) {
  .wrapper {
    .section-wrapper {
      section {
        max-width: 960px;
      }
      .advise {
        .adviser-list {
          .adviser {
            width: 23%;

            img {
              scale: 1;
            }
          }
        }
        .button {
          width: 50%;
        }
      }
      .exp {
        .exp-form {
          flex-direction: row;
          justify-content: space-evenly;
          padding: 0 30px;
          .card {
            margin: 0;
          }
          .form {
            height: fit-content;
            width: 50%;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .wrapper {
    .section-wrapper {
      section {
        max-width: 1140px;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .advise {
        .adviser-list {
          .adviser {
            

            img {
              scale: 0.9;
            }
          }
        }
        .button {
          width: 50%;
        }
      }
      .quiz {
        flex-direction: row;
        column-gap: 60px;
        .content {
          flex: 1;
          .title {
            text-align: left;
          }
          .button {
            width: 50%;
          }
        }
        .banner {
          flex: 1;
        }
      }
      .exp {
        .exp-form {
          padding: 0 100px;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .wrapper {
    .section-wrapper {
      section {
        max-width: 1320px;
      }
      .advise {
        .adviser-list {
          .adviser {
            

            img {
              scale: 0.7;
            }
          }
        }
        .button {
          width: 50%;
        }
      }
      .quiz {
        .content {
          .button {
            margin-top: 48px;
          }
        }
      }
    }
  }
}
