.form-login {
  text-align: center;
  .title {
    font-size: 34px;
    line-height: 1.334;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .des {
    font-size: 16px;
    color: #767676;
    margin-bottom: 16px;
  }

  .input-wrapper {
    position: relative;

    margin-top: 28px;
  }

  .notice {
    color: red;
    font-size: 14px;
    text-align: center;
  }
  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    padding: 10px 8px;

  }
}
