.wrapper {
    background: #eb1b2f;
    border-radius: 20px;
    box-shadow: 2px 3px 4px #cacaca;
    text-align: center;
    padding: 5px 8px;
    color: #fff!important;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    font-family: TTNormsPro-Bold;
    width: 100%;
    display: block;
 
}