.body-wrapper {
   
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin: 60px auto;
  
.title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  text-align: center;

  .des {
      text-transform: none;
      color: var(--primary-color);
  }
}

.select-role-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .select-role {
      max-width: 412px;
      padding: 16px;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          margin: 16px 16px;
    .select-role-btn {
      width: 100%;
      transition:all 0.2s ease-in-out;

      &:hover {
          background-color: var(--white);
          color: var(--red) !important;
      }
    }

    .image-select {
      width: 100%;
     
    }
  }
}
}


@media screen and (min-width: 744px) {

  .select-role {
      flex-basis: 33.333%;
      max-width: 33.333%;
      flex-grow: 0;
  }

  .body-wrapper {
      max-width: 960px;
  }

  
  
}