.wrapper {
  font-size: 14px;
  background-color: #f6f8fb;
  padding-top: 36px;


  .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 12px;
    background-color: #f6f8fb;
    .left {
      display: flex;
      align-items: center;
      flex-direction: column;

      .logo {
        width: 200px;
        margin-bottom: 24px;
        max-width: 230px;
      }

      .address {
        margin-bottom: 20px;
        width: 100%;
      }

      .contact {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        svg {
          width: 13px;
          height: 13px;
          
          border: 1px solid #acacac;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #acacac;
          margin-right: 8px;
          padding: 4.5px;
          
        }
        .email,
        .phone {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }
  }

  .line-copyright {
    text-align: center;
    padding: 12px;
    background-color: var(--red);
    color: var(--white);
    margin-top: 30px;
  }
}

@media (min-width: 576px) {
  .wrapper {
    .container{
   
      .left {
        min-width: 395px;
        .address {
          width: fit-content;
        }
        .contact {
          .email, .phone {
            flex: 1;
          }
        }
      }
    }
  }
}
