.wrapper {
  margin-top: 3px;
  .form {
    margin-bottom: 20px;
    .form-group {
      .ques {
        font-size: 17px;
        span {
          text-decoration: underline;
        }
      }
      .input-wrapper {
        display: grid;
        row-gap: 15px;
        margin-top: 10px;
        .input {
          background: #fff;

          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          border: 1px solid #d2d2d2;
          border-radius: 3px;

          padding: 5px 10px;

          cursor: pointer;

          &:has(input:checked) {
            background-color: var(--red);
            color: var(--white);
          }
          label {
            width: 100%;
            cursor: pointer;
            padding-left: 24px;
          }
        }
      }
    }

    .title-group {
      text-transform: capitalize;
      margin: 10px 0 13px;
      font-size: 17px;
    }
  }
  .paginate {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 25px;
    .pg-button {
   
      max-width: 185px;
      overflow: hidden;
    }
    .prev-btn,
    .next-button {
      cursor: pointer;
      display: flex;
      column-gap: 10px;
      
      max-width: 185px;
      overflow: hidden;
      align-items: center;
      padding: 10px 14px;
      color: var(--white);
      background-color: #1f2356;
      border-radius: 7px;
      font-size: 16px;
      font-weight: 400;
      font-family: TTNormsPro-Bold;
      span {
        max-width: 162px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: block;
      }
      svg {
        width: 16px;
        height: 16px;
      }
      &:disabled {
        visibility: hidden;
      }
    }
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .bar {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 10px;

      &::-webkit-progress-bar {
        background-color: #e9ecef;
        border-radius: 30px;
      }

      &::-webkit-progress-value {
        background-color: #34c38f;

        border-radius: 30px;
      }
    }

    .label {
    }
  }
  .submit-button {
    margin: 12px auto;
    width: fit-content;
    a {
      padding: 8px 20px;
    }
  }
}

.disabled {
  visibility: hidden !important;
}
