.input-wrapper {
  position: relative;
  flex: 1;
  margin-top: 16px;

  .input-relative {
    position: relative;

    .input {
      width: 100%;
      padding: 14px;
      border-radius: 10px;
      height: 50px;
      cursor: pointer;
      border: 1px solid var(--grey);

      &:focus {
        border-color: var(--blue);
      }

      &.error {
        border-color: var(--red);
      }
    }

    .label {
      position: absolute;
      font-size: 14px;
      top: 50%;
      transform: translateY(-50%);
      left: 18px;
      background-color: #fff;
      line-height: 1;
      padding: 0 6px;
      display: flex;
      z-index: -1;
      transition: all 0.15s linear 0s;
      color: var(--black);

      &.date {
        z-index: 0;
        user-select: none;
        cursor: pointer;
        left: 14px;
        padding-left: 10px;
        width: 130px;
      }
      &.error {
        color: red !important;
      }
    }

    .icon-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      justify-content: center;
      align-items: center;
      right: 18px;
      display: flex;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      width: 40px;
      height: 40px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &.active {
        .showIcon {
          display: block;
        }

        .hideIcon {
          display: none;
        }
      }

      .showIcon {
        display: none;
      }

      .hideIcon {
        display: block;
      }
    }
  }

  .notice-error {
    margin: 6px 0 10px 6px;
    font-size: 10px;
    text-align: start;
  }
}
