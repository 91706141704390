@import "normalize.css";

@font-face {
  font-family: TTNormsPro-Normal;
  src: url(../../assets/fonts/TT-Norms-Pro-Normal.ttf) format("truetype");
}

@font-face {
  font-family: TTNormsPro-Medium;
  src: url(../../assets/fonts/TT-Norms-Pro-Medium.ttf) format("truetype");
}

@font-face {
  font-family: TTNormsPro-Bold;
  src: url(../../assets/fonts/TT-Norms-Pro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: TTNormsPro-DemiBold;
  src: url(../../assets/fonts/TT-Norms-Pro-DemiBold.ttf) format("truetype");
}

@font-face {
  font-family: TTNormsPro-MediumItalic;
  src: url(../../assets/fonts/TT-Norms-Pro-Medium-Italic.ttf) format("truetype");
}

:root {
  --red: #ec1b30;
  --black: #313131;
  --white: #fff;
  --blue: #53c5e8;
  --grey: #666;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
  }
}

body {
  font-size: 16px;
  font-family: TTNormsPro-Normal;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
  background-color: transparent;
}

button {
  cursor: pointer;
}

ul,
li {
  list-style-type: none;
}

a[href] {
  text-decoration: none;
  color: var(--black);
}

img, svg {
  vertical-align: middle;
  width: 100%;
  animation: fadeIn 1s linear 0s alternate forwards;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
   visibility: hidden;
   display: none !important;
   pointer-events: none;
   height: 0;
   width: 0;
   margin: 0;
}

input[type="radio"],input[type="checkbox"] {
  padding: 0;
  height: 1px;
  width: 1px;
  margin-bottom: 0;

  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
    background: #f0f0f0;
    z-index: 2;
  }

  &:checked {
    &::after {
      content: "";
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      -webkit-transform: translateY(-50%) rotate(45deg);
      -moz-transform: translateY(-50%) rotate(45deg);
      width: 5px;
      height: 9px;
      border: solid red;
      border-width: 0 2px 2px 0;
      z-index: 3;
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
