.wrapper {
  border-bottom: 2px solid #fbd1d5;
  position: relative;

  .nav-wrapper {
    margin: 0 auto;
    height: 50px;
    position: relative;
  }

  .nav {
    
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      max-width: 150px;
      width: 115px;
      img {
        width: 100%;
      }
    }

    .menu {
      display: none;
      flex-wrap: wrap;
      > .item {
        position: relative;
        font-weight: 600;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0px;
          background-color: #ea1e32;
          z-index: -1;
        }
        > a {
          padding: 25px 20px;
          display: block;
         
          span {
            position: relative;
          }
          
        }
        &:has(.active) {
          &::after {
            height: 3px;
          }
        }
        
        &:hover {
          &::after {
            height: 3px;
          }
        }
        &:not(:last-child) {
          > a {

            span {

              &::before {
                content: '';
                position: absolute;
                top: 0;
                right: -20px;
                height: 100%;
                width: 4px;
                background-color: #ccc;
                border-radius: 90px;
  
              }
            }
          }
        }
      }
      
    }

    .sub-menu {
      > .sub-item {
        padding: 0 10px;
        height: 38px;
        line-height: 38px;
        border-bottom: 1px solid #ccc;
        > a {
          width: 100%;
          height: 100%;
          display: block;
        }

        &:hover {
          background-color: var(--red);
          a {
            color: var(--white);
          }
        }
      }
    }

    .user-login {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
     
      span {
        margin-left: 4px;
        color: var(--red);
        display: none;
      }
      img {
        width: fit-content;
      }
     
    }
  }

  .menu-button-wrapper {
    height: 30px;
  }


  .menu-mobile {
    

    a {
      padding: 10px;
      width: 100%;
      display: block;
      height: 100%;
      font-size: 16px;
      border-bottom: 1px solid #ccc;
      &:hover {
        background-color: var(--red);
        color: var(--white);
      }

    }
    .sub-item-mobile {
      a {
        padding-left: 25px;
      }
    }
  }

}
.popper {
  box-shadow: 0 2px 12px 0 rgba(153, 153, 153, 0.45);
  .link-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 20px;
    transition: all 0.4s ease-in-out;
    color: var(--red);
    cursor: pointer;
    &.signup {
      border-top: 1px solid var(--red);
    }
    &:hover {
      background-color: var(--red);
      color: var(--white);
      span {
        color: var(--white);
      }
    }
    span {
    transition: all 0.4s ease-in-out;
    display: block;
    color: var(--red);
    font-size: 16px;

    }
    .icon {
      width: 18px;
      height: 18px;

      

    }
  }
}
@media (min-width: 576px) {
  .wrapper {
    .nav-wrapper {
      max-width: 540px;
    }
  }
}

@media (min-width: 768px) {
  .wrapper {
    .nav-wrapper {
      max-width: 720px;
      padding: 0;
      height: auto;
      .nav {
        padding: 0 20px;
      }
      .menu-button-wrapper {
        display: none;
      }
      
      .logo {
        width: auto;
      }
      .menu {
        display: flex;
        justify-content: center;
      }

      .menu-mobile {
        display: none;
      }

      .user-login {
        
        span {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .wrapper {
    .nav-wrapper {
      max-width: 960px;
    }
  }
}

@media (min-width: 1200px) {
  .wrapper {
    .nav-wrapper {
      max-width: 1140px;
    }
  }
}

@media (min-width: 1400px) {
  .wrapper {
    .nav-wrapper {
      max-width: 1320px;
    }
  }
}
